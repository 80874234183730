//  ================================================================================================
//  ? TIP:  It is recommended to use this file for overriding bootstrap variables.
//  ================================================================================================

$modal-xl: 90% !default;

// Modal XL
@media (min-width: 1200px) {
  .modal-xl {
    margin-left: 5%;
    margin-right: 5%;
  }
}