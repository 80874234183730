/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

@import '@styles/base/bootstrap-extended/_variables.scss';
@import '@styles/react/libs/tables/react-dataTable-component.scss';

$bg-light-win: rgba(40, 199, 111, 0.12) !important;
$bg-light-lose: rgba(234, 84, 85, 0.12) !important;

//** 
//** GLOBAL
//**
.p-01 { padding: 0.1rem !important; }
.p-02 { padding: 0.2rem !important; }
.p-05 { padding: 0.5rem !important; }

.pb-01 { padding-bottom: 1px; }
.pb-02 { padding-bottom: 2px; }
.pb-03 { padding-bottom: 3px; }
.pb-04 { padding-bottom: 4px; }
.pt-01 { padding-top: 1px; }
.pt-02 { padding-top: 2px; }
.pt-03 { padding-top: 3px; }
.pt-04 { padding-top: 4px; }
.pl-01 { padding-left: 1px; }
.pl-02 { padding-left: 2px; }
.pl-03 { padding-left: 3px; }
.pl-04 { padding-left: 4px; }
.pl-05 { padding-left: 5px; }
.pl-1 { padding-left: 10px; }

.mr-01 { margin-right: 1px; }
.mr-02 { margin-right: 2px; }
.mr-03 { margin-right: 3px; }
.mr-04 { margin-right: 4px; }
.mr-05 { margin-right: 5px; }
.mr-1 { margin-right: 10px; }
.mr-2 { margin-right: 20px; }
.mr-3 { margin-right: 30px; }
.mr-4 { margin-right: 40px; }
.mr-5 { margin-right: 50px; }
.mr-6 { margin-right: 60px; }
.ml-01 { margin-left: 1px; }
.ml-02 { margin-left: 2px; }
.ml-03 { margin-left: 3px; }
.ml-04 { margin-left: 4px; }
.ml-05 { margin-left: 5px; }
.ml-1 { margin-left: 10px; }
.ml-2 { margin-left: 20px; }
.ml-3 { margin-left: 30px; }
.ml-4 { margin-left: 40px; }
.ml-5 { margin-left: 50px; }
.ml-6 { margin-left: 60px; }
.mt-01 { margin-top: 1px; }
.mt-02 { margin-top: 2px; }
.mt-03 { margin-top: 3px; }
.mt-04 { margin-top: 4px; }
.mt-05 { margin-top: 5px; }
.mt-1 { margin-top: 10px; }
.mt-2 { margin-top: 20px; }
.mt-3 { margin-top: 30px; }
.mt-4 { margin-top: 40px; }
.mt-5 { margin-top: 50px; }
.mt-6 { margin-top: 60px; }
.mb-01 { margin-bottom: 1px; }
.mb-02 { margin-bottom: 2px; }
.mb-03 { margin-bottom: 3px; }
.mb-04 { margin-bottom: 4px; }
.mb-05 { margin-bottom: 5px; }
.mb-1 { margin-bottom: 10px; }
.mb-2 { margin-bottom: 20px; }
.mb-3 { margin-bottom: 30px; }
.mb-4 { margin-bottom: 40px; }
.mb-5 { margin-bottom: 50px; }
.mb-6 { margin-bottom: 60px; }

.mh-02 { margin-left: 2px; margin-right: 2px; }

.r-01 { right: 1px; }
.r-02 { right: 2px; }
.r-03 { right: 3px; }
.r-04 { right: 4px; }
.r-1 { right: 10px; }
.r-2 { right: 20px; }
.r-3 { right: 30px; }
.r-4 { right: 40px; }
.r-5 { right: 50px; }
.r-6 { right: 60px; }
.l-01 { left: 1px; }
.l-02 { left: 2px; }
.l-03 { left: 3px; }
.l-04 { left: 4px; }
.l-1 { left: 10px; }
.l-2 { left: 20px; }
.l-3 { left: 30px; }
.l-4 { left: 40px; }
.l-5 { left: 50px; }
.l-6 { left: 60px; }

.green { color: green }

.bold { font-size: bold; }
.identification { 
  font-size: 0.7em;
}
.search-select {
  width: inherit;
  height: -webkit-fill-available;
  border: 1px solid #d9d6de;
  border-radius: 6px;
  padding: inherit;
}

.export-info-container { 
  height: 100px;
  background-color: #eeeeee82;
  border-radius: 10px;
  padding: 10px 10px;
  margin-bottom: 10px;
}

.white-space-nowrap { white-space: nowrap; }

.text-align-center { text-align: center; }
.clickable { cursor: pointer; }
.height-max { height: 100%; }
.width-max { width: 100%; }
.scroll-x { width: '100%' !important; overflow: 'scroll' !important}
.cursor-pointer { cursor: pointer; }
.border-right { border-right: 1px solid #d6d6d6 }

// No scrollbar
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: transparent;
}
::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

// FLEX BOX
// align-items-start
// align-items-end
// align-items-center
// align-items-stretch
// align-items-baseline
// justify-content-center
// justify-content-start
// justify-content-end
// justify-content-flex-start
// justify-content-flex-end
// justify-content-left
// justify-content-right
// justify-content-between
// justify-content-around

// Workaround for badges in menus
.menu-content {
  span.badge {
    float: right !important;
  }
}

.activity-group {
  span {
    color: white
  }
}

//** 
//** SVG
//** 
.root-node rect {
  stroke-width: 0;
  fill: white;
}

.branch-node rect {
  fill: white;
  stroke-width: 0;
}

.leaf-node rect {
  fill: white;
  stroke-width: 0;
}

.root-node tspan, .branch-node tspan, .leaf-node tspan {
  stroke-width: 1;
  stroke: rgb(98, 95, 110);
}

//** 
//** Choice Card
//**
.situation-container {
  overflow: hidden;
  position: relative;
}
.choice-container-new-choice {
  height: 800px;
}
.choice-card {
  .title-container {
    text-align: center;
    .choice-title {
      font-size: 1.3em;
      line-height: 1.2em;
      text-align: center;
      font-weight: 400;
    }
  }
  .answer-container-row {
    height: 80px;
    .answer-container-col {
      padding: 10px;
      height: 80px;
      .answer {
        max-height: 60px;
        font-size: 1.1em;
        line-height: 1.2em;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
    }
  }
  textarea {
    padding: 2px !important;
  }
}
.cheatsheet-container {
  position: absolute;
  margin: 15px 30px;
  width: auto;
}
.cheatsheet {
  position: relative;
  background-color: white;
  border-radius: 10px;
  padding: 7px 10px;
  margin-bottom: 5px;
}

// ** Choice Table
.widget-icon-content {
  position: relative;
}
.badge {
  // badge-down
  // to align badge under any element
  &.badge-down {
    position: absolute;
    bottom: -11px;
    right: -9px;
    min-width: 1.429rem;
    min-height: 1.429rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.786rem;
    line-height: 0.786;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    &.badge-sm {
      bottom: -0.5rem;
      right: -0.5rem;
    }
  }
}
 
//** 
//** Goal Card
//**
.goal-card {
  z-index: 1;

  .edit-goal-btn {
    position: absolute;
    right: 10px;

  }

  // Test
  .test-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    .test-content {
      padding-top: 0.2rem;
      padding-left: 0.5rem;
      .test-title {
        margin: 0;
      }
    }
  }

  // Win & Lose
  .win-item {
    color: #70d79e;
  }
  .lose-item {
    color: #FD9CA3;
  }

  // Choice & Job
  .stat-block {
    align-items: center;
    flex: 1;
    circle {
      stroke: transparent;
      fill: transparent;
    }
    a {
      color: #5e5873;
    }
  }

  // goal not done yet
  .point-warning {
    height: 12px;
    width: 12px;
    text-align: center;
    border-radius: 50%;
    background-color: #ff9f43 !important;
    position: absolute;
    top: 6px;
    left: 6px;
    &:before {
      background-color: rgba(255, 159, 67, 0.12) !important;
      content: "";
      height: 20px;
      width: 20px;
      display: block;
      position: absolute;
      top: -0.285rem;
      left:-0.285rem;
      border-radius: 50%;
    }
  }
}

// Events
.event-scroll-area {
  height: 70px;
  top: 10px;
  z-index: 0;
  margin: auto;
}
.event-container {
  position: relative;
  .text-container {
    margin-bottom: 20px;
    &.event-no-impact {
      margin-bottom: -10px;
    }
    p {
      box-shadow: 0 4px 8px 0 rgba($black, 0.12);
      font-size: 0.85em;
      text-align: center;
      background-color: white;
      padding: 0.3em;
      border-radius: 0.4em;
      color: #b9b9c3;
    }
  }
  .bonus-container {
    position: absolute;
    bottom: -16px;
    background-color: rgb(255, 247, 247);
    box-shadow: 0 2px 4px 0 rgba($black, 0.12);
    border-radius: 3em;
    padding: 0 3px;
    margin-right: 6px;
    align-items: center;
    justify-content: center;
  }
  .flex-left {
    left: 10px;
  }
}
.input-impact {
  font-weight: bold;
}
.positive {
  color: green;
}
.negative {
  color: red;
}

.select-sm {
  .select__control {
    padding: 0;
    border-radius: 0.357rem;
    height: 10px;
  }
  .select__input-container {
    padding: 0;
  }
  font-size: 0.857rem;
}
.form-switch .form-check-input {
  width: 4rem;
}

// Add goal Button
.add-goal-container {
  text-align: center;
}

// Age selection & table / tree toggling in Choice View
.choice-view {
  .active {
    background-color: #7367f0 !important;
    color: #fff !important;
  }
}

//**
//** IMAGES
//**

// Image
.image-active {
  .card-body {
    box-shadow: 0 0px 10px 10px #7367f0;
    outline: 8px solid #7367f0;
    border-radius: 4px;
  }
}
.image-title {
  padding: 0 0 5px;
}

//**
//** MAP
//**
.map-simulation {
  
  background-color: rgba(0, 0, 0, 0.075);
  width: 500px;
  height: 1084px;
  position: relative;
  
  .goal {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    background-color: #5dbb74;
    box-shadow: 0 2px 4px 0 rgba($black, 0.12);
    position: absolute;

    &:first-child {
      top: 492px; // 50% 1084
      left: 200px; // 50% 500
    }

    .img {
      position: relative;
      top: 25%;
      left: 25%;
    }
  }
}
